/* Copyright 2023 Deepsquare Association
/* This file is part of Nexus.
/* Nexus is free software: you can redistribute it and/or modify it under the terms of the GNU General Public License as published by the Free Software Foundation, either version 3 of the License, or (at your option) any later version.
/* Nexus is distributed in the hope that it will be useful, but WITHOUT ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the GNU General Public License for more details.
/* You should have received a copy of the GNU General Public License along with Nexus. If not, see <https://www.gnu.org/licenses/>.

/**
 * For MUI/Tailwind CSS interoperability, see https://mui.com/guides/interoperability/#tailwind-css
 */
@import '../styles/vendor/react-toastify.css';

@import '../../node_modules/xterm';

@tailwind base;
@tailwind components;
@tailwind utilities;

/**
 * Typography
 * ==========
 */
p {
  margin-top: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

a {
  @apply text-primary no-underline;
}

strong {
  @apply text-primary;
}

kbd {
  @apply px-2 py-1 text-xs font-semibold bg-gray-100 border border-gray-400 border-solid rounded-lg shadow whitespace-nowrap;
}

/**
 * Custom scrollbar
 * ================
 */

/* width */
::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-neutral;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-white bg-opacity-10;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-secondary;
}

.overlay {
  z-index: 100000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup {
  background-color: white;
  box-shadow: 0px 0px 10px #888;
  padding: 20px;
  max-width: 500px;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 10px;
}

.my-json-editor {
  /* define a custom theme color */
  --jse-theme-color: #8a3aeb;
  --jse-theme-color-highlight: #ae80ea;
}
